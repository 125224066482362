import { CFormInput } from '@coreui/react-pro'
import React, { useState } from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const TextInputField = ({
  title,
  type,
  placeholder,
  icon,
  register,
  name,
  modifiedRegister,
  isError,
  isTextInputParent = true,
  isDisabled = false,
  accept = null,
}) => {
  const [showPass, setShowPass] = useState(false)
  const customClassName = `common-text-input text-input-field ${icon} ${
    isError && 'input-error-class'
  }`

  const pwdEyeDiv = () => {
    if (type === 'password') {
      return (
        <FontAwesomeIcon
          icon={showPass ? faEye : faEyeSlash}
          className="pwd-eye-icon"
          onClick={() => setShowPass(!showPass)}
        />
      )
    }
  }

  const inputType = () => {
    if (type === 'password') {
      return !showPass ? 'password' : 'text'
    }
    return type
  }

  return (
    <div className={isTextInputParent && 'text-input-parent'}>
      {title && <label className="text-input-label">{title}</label>}
      <div className="input-field-parent">
        {modifiedRegister ? (
          <>
            <input
              type={inputType()}
              placeholder={placeholder}
              className={customClassName}
              disabled={isDisabled}
              accept={accept}
              {...modifiedRegister}
            ></input>
            {pwdEyeDiv()}
          </>
        ) : (
          <>
            <input
              type={inputType()}
              placeholder={placeholder}
              className={customClassName}
              disabled={isDisabled}
              {...register(name)}
            ></input>
            {pwdEyeDiv()}
          </>
        )}
      </div>
    </div>
  )
}

export default TextInputField
