import React, { useEffect } from 'react'
import { Navigate, useLocation, useOutlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { USERROLES } from 'src/constant'

const ProtectedLayout = () => {
  const { user, logout, logoutAllTabs, loginAllTabs } = useAuth()
  const outlet = useOutlet()
  const { pathname } = useLocation()

  const unauthorised = async () => {
    logout()
    await window.open(
      `${process.env.REACT_APP_URL_ADMIN_PANEL}loginForNextChannel.html?target=${process.env.REACT_APP_URL_NEXT_FRONTEND}`,
      // "http://localhost:3002/loginforadminpanel.html?target=http://localhost:3000",
      '_blank',
      'noreferrer',
    )
  }

  useEffect(() => {
    logoutAllTabs()
    loginAllTabs()
  }, [])

  if (!user) {
    return <Navigate to="/login" />
  }
  if (
    pathname != '/' &&
    user.userInfo.userRole === USERROLES[3] &&
    !pathname.startsWith('/costumer-success')
  ) {
    unauthorised()
  }
  if (
    pathname != '/' &&
    user.userInfo.userRole === USERROLES[0] &&
    !pathname.startsWith('/business')
  ) {
    unauthorised()
  }
  return <div>{outlet}</div>
}

export default ProtectedLayout
