import { CMultiSelect } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import './style.css'

const MultiSelectField = ({
  optionsData,
  setterFun,
  setterFun2,
  isUpdateItem,
  isUpdateItem2,
  label,
  multiple,
  iconClass,
  selectionType,
  placeholder,
  setInValidFun,
  isParrentClass = true,
  isAsyncSelect = false,
  isCommunes = null,
}) => {
  const [displayOptions, setDisplayOptions] = useState(() => {
    if (isUpdateItem) {
      return optionsData.map((ele) => {
        if (
          isUpdateItem.some(
            (updateEle) => (updateEle.id || updateEle.value) == (ele.id || ele.value),
          )
        ) {
          return {
            ...ele,
            selected: true,
          }
        }
        return ele
      })
    }
    return optionsData
  })
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputValue, setValue] = useState('')

  const multiSelectVars = {
    '--cui-form-multi-select-focus-border-color': '#ffd400',
    '--cui-form-multi-select-border-color': 'red',
  }

  const fetchData = (searchValue, callback) => {
    if (searchValue) {
      const filteredOptions = isCommunes.filter((e) =>
        e.communes.toLowerCase().includes(searchValue.toLowerCase()),
      )
      callback(filteredOptions)
    } else {
      const filteredOptions = optionsData.filter((e) =>
        e.district.toLowerCase().includes(searchValue.toLowerCase()),
      )
      callback(filteredOptions)
    }
  }

  const handleInputChange = (value) => {
    setValue(value)
  }

  const handleChange = (value) => {
    setSelectedValue(value)
    setterFun(value.filter((ele) => ele.district)?.map((ele) => ele.id || ele.value))
    setterFun2(value.filter((ele) => ele.communes)?.map((ele) => ele.id || ele.value))
  }

  const dropdownSelectDiv = (e) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Image src={userIcon} /> */}
        <span style={{ marginLeft: 5 }}>{e.district || e.communes}</span>
      </div>
    )
  }

  useEffect(() => {
    if (isUpdateItem) {
      setterFun(() => {
        return optionsData
          .map((ele) => {
            if (
              isUpdateItem.some(
                (updateEle) => (updateEle.id || updateEle.value) == (ele.id || ele.value),
              )
            ) {
              return ele.id || ele.value
            }
          })
          .filter((ele) => ele)
      })
    }
    if (isCommunes && isUpdateItem) {
      const selectedDistAndComunne = isUpdateItem.concat(isUpdateItem2)
      setSelectedValue(selectedDistAndComunne)
      setterFun(isUpdateItem.map((ele) => ele.id || ele.value))
      setterFun2(isUpdateItem2.map((ele) => ele.id || ele.value))
    }
  }, [isUpdateItem])

  const selectedOptions = (selectedValues) => {
    console.log('selectedValues', selectedValues)
    if (setInValidFun && selectedValues.length) {
      setInValidFun(false)
    } else if (setInValidFun && !selectedValues.length) {
      setInValidFun(true)
    }
    setterFun(selectedValues.map((ele) => ele.id || ele.value))
  }

  if (isAsyncSelect) {
    return (
      <div className="multi-select-parent">
        <div className="select-box-label">{label}</div>
        <AsyncSelect
          cacheOptions
          defaultOptions
          placeholder={placeholder}
          value={selectedValue}
          getOptionLabel={(e) => dropdownSelectDiv(e)}
          getOptionValue={(e) => e.id || e.value}
          loadOptions={fetchData}
          isMulti
          onInputChange={handleInputChange}
          onChange={(value) => {
            handleChange(value)
          }}
          className={iconClass}
          classNamePrefix="field-select-inner"
        />
      </div>
    )
  } else {
    return (
      displayOptions && (
        <div className={`${isParrentClass && 'multi-select-parent'}`}>
          <div className="select-box-label">{label}</div>
          <CMultiSelect
            style={multiSelectVars}
            selectAllLabel="Alle auswählen"
            className={iconClass ?? ''}
            options={displayOptions}
            placeholder={placeholder}
            multiple={multiple ?? true}
            selectionType={selectionType ? selectionType : 'text'}
            onChange={(selected) => {
              selectedOptions(selected)
            }}
          />
        </div>
      )
    )
  }
}

export default MultiSelectField
